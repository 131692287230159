import React, { useEffect, Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Toolbar, AppBar, Typography, Divider } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { analyticActions } from '_actions';
import ZnDateRange from 'common/znFields/ZnDateRange';
import moment from 'moment';
import CartLeadsInByTime from './components/Carts';
import CartLeadsInDepartmentByTime from './components/Carts/CartLeadsInDepartmentByTime';
import LeadsByCountryAndDept from './components/Carts/LeadsByCountryAndDept';
import { ZnDepartmentSimpleSelect } from 'common/znFields/ZnDepartmentSelect';
import { ZnDivider } from 'common/znFields/ZnDivider';
import { ZnSimpleSourceSelect, ZnSourceSelect } from 'common/znFields/ZnSourceSelect';
import { ZnButton } from 'common/znFields/ZnButton';



const defaultEndDate = new Date();
const defaultStartDate = new Date().setDate(defaultEndDate.getDate() - 365)



const useStyles = makeStyles(theme => ({
  cardbox: {
    maxWidth: '100px',
    backgroundColor: 'lightgray',
    borderRadius: 20
  },
  root: {
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const randArray = (elements = 10, min = 10, max = 100) => {
  let result = new Array(elements)
  result = result.fill(0).map(() => {
    const rand = Math.random()
    return min + Math.floor(rand * (max - min))
  })
  return result
}



const Analytics2 = () => {

  const classes = useStyles();

  const cartData = useSelector(state => state.analytics.cart2)
  const [filters, setFilters] = useState({})

  const dispatch = useDispatch();

  useEffect(() => {

    const ff = filters;
    ff.from = moment(ff.startDate).format('YYYY-MM-DD')
    ff.to = moment(ff.endDate).format('YYYY-MM-DD')
    delete ff.startDate
    delete ff.endDate

    dispatch(analyticActions.getCart2(ff));
  }, [filters]);



  const handleDateRangeChange = (dateRange) => {
    setFilters({
      ...filters,
      startDate: dateRange.target?.value?.startDate,
      endDate: dateRange.target?.value?.endDate,
    })
  }

  const handleChangeDepartment = value => {
    console.log("handleChangeDepartment", value)
    setFilters({
      ...filters,
      departmentId: value
    })
  }

  const handleChangeSource = value => {
    setFilters({
      ...filters,
      sourceId: value
    })
  }

  const handleOnClickReset = () => {
    setFilters({})
  }



  return (
    <Fragment>
      <AppBar color="default" position="sticky">
        <Toolbar>
          <ZnDateRange
            onChange={handleDateRangeChange}
            defaultStartDate={defaultStartDate}
            defaultEndDate={defaultEndDate}
          />
          <ZnDivider />
          <ZnDepartmentSimpleSelect
            label="practices"
            fullWidth={false}
            value={filters.departmentId ?? null}
            onChange={handleChangeDepartment} />
          <ZnDivider />
          <ZnSimpleSourceSelect
            fullWidth={false}
            value={filters.sourceId ?? null}
            onChange={handleChangeSource} />


          <ZnButton onClick={handleOnClickReset}>reset</ZnButton>

        </Toolbar>
      </AppBar>
      <div className={classes.root}>
        <Grid
          container
          spacing={4}
        >
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Typography variant='h4'>LEADS RECEIVED</Typography>
          </Grid>


          <Grid item lg={12} md={12} xl={12} xs={12}>
            <CartLeadsInByTime
              title="All Leads Received "
              subheader="All incoming leads compared with previous year"
              categories={cartData?.leadsInByTime?.categories}
              series={cartData?.leadsInByTime?.series}
            />
          </Grid>

          <Grid item lg={12} md={12} xl={12} xs={12}>
            <CartLeadsInDepartmentByTime
              title="Leads Received By Practices"
              subheader="All incoming leads assigned to a department"
              categories={cartData?.leadsInDepartmentsByTime?.categories}
              series={cartData?.leadsInDepartmentsByTime?.series}
            />
          </Grid>



          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Typography variant='h4'>LEADS RECEIVED BY COUNTRY</Typography>
          </Grid>

          {cartData?.leadsInDepartmentsByJurisdiction && cartData.leadsInDepartmentsByJurisdiction.map((v, k) => {
            return (
              <Grid item lg={4} >

                <LeadsByCountryAndDept key={k}
                  categories={v.categories}
                  series={v.series.data}
                  name={v.name}
                />
              </Grid>
            )
          })}





        </Grid>
      </div>
    </Fragment>
  );
};

export default Analytics2;
